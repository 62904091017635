@import '_globals.less';

// MAIN NAVIGATION
// ============================

@keyframes append-animate {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}


.main-navigation {
    background-color: @c-lightblue-400;
    height: 60px;
    position: sticky;
    top: 0px;
    width: 100%;

    ul {
        background-color: @c-lightblue-400;
        padding: 0 11px;
        box-sizing: border-box;
    }

    li:hover,
    li.on {
       background-color: @c-lightblue-300;
    }

    a {
        font-weight: 400;
        transition: none;

        &:hover {
            color: @c-primary-1;
        }
    }

    &.mobile-view {
        display: none;
    }

    &.desktop-view {
        z-index: 100;

        #home-image {
            margin: 0 0 0 6.5px;
            min-width: 70px;

            a {
                padding: 0;

                svg {
                    margin: 0 auto;
                }
            }
        }

        .navigation--arrow-down {
            display: inline-block;
            height: auto;
            width: 21px;
            position: relative;
            top: 4px;
            padding-left: 7.5px;

            path {
                fill: @c-blue-200;
            }
        }

        .home-image {
            height: 36px;
            position: relative;
            top: 12px;
            right: -10px;

            path {
                fill: @c-primary-1;
            }
        }
    }

    // LEVEL 1
    // --------------------------------

    ul[data-level="1"] {
        display: flex;
        font-size: 0;
        height: 100%;
        list-style: none;
        margin: 0 auto;
        max-width: 1280px;
        min-width: 993px;
        width: 100%;
        justify-content: space-between;

        > li {
            color: @c-primary-1;
            display: inline-block;
            font-size: 16px;
            height: 60px;
            position: relative;
            text-transform: uppercase;
            transition: background-color .4s;

            &:after {
                content: '';
                display: block;
                height: 11px;
                left: 0;
                position: absolute;
                top: 100%;
                width: 100%;
                z-index: 2;
            }

            &:hover > ul {
                display: block;
            }

            &:last-child {
                > ul {
                    right: 0;
                }
            }

            > a {
                display: inline-block;
                line-height: 60px;
                padding: 0 15px;
                white-space: nowrap;
            }
        }


        // LEVEL 2
        // --------------------------------

        ul[data-level="2"] {
            display: none;
            position: absolute;
            text-align: left;
            top: 71px;
            z-index: 10;

            > li {
                display: block;
                min-width: 200px;
                padding: 0 15px;
                text-transform: none;

                a {
                    display: block;
                    padding: 15px 0;
                    transition: color .4s;
                }

                &.on a {
                    font-weight: 500;
                }
            }
        }
    }
}

.navigation--back-to-top-button {
    position: fixed;
    z-index: 9999;
    overflow: hidden;
    flex-wrap: nowrap;
    box-sizing: border-box;
    padding: .5em;
    cursor: pointer;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    margin: 5px;
    transition: all .3s ease-in-out;
    border-radius: 20px;
    right: 0;
    left: auto;
    bottom: 50px;
    color: @c-blue-200;
    background-color: rgb(215, 236, 254);

    svg {
        height: auto;
        width: 24px;

        path {
            fill: @c-blue-200;
        }
    }

    .navigation--back-to-top-button--text {
        display: none;
    }

    &:hover {
        opacity: 0.7;

        .navigation--back-to-top-button--text {
            display: inline-block;
        }
    }
}

@media screen and (max-width: 1130px) {
    .main-navigation {
        bottom: 0;
        box-sizing: border-box;
        height: auto;
        overflow: auto;
        padding: 60px 60px 0 20px;
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transition: all 200ms;
        width: 350px;
        z-index: @offCanvasZIndex;

        &.mobile-view {
            display: block;
        }

        &.desktop-view {
            display: none;
        }

        // LEVEL 1
        // --------------------------------

        ul[data-level="1"] {
            display: block;
            height: auto;
            max-width: none;
            min-width: 0;

            > li {
                display: block;
                text-align: left;
                width: 100%;

                > a {
                    font-size: 18px;
                    padding-bottom: 8px;
                    padding-top: 8px;
                }
            }

            + .js-userpanel-list {
                margin-top: 20px;
            }
        }

        // LEVEL 2
        // --------------------------------

        ul[data-level="2"] {
            display: none !important;
        }


        .js-userpanel-list {
            display: block;
            list-style: none;

            > li {
                > a {
                    font-size: 14px;
                    line-height: 32px;
                    display: block;
                    padding: 0 15px;

                    &.bookmark {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// ***********************************************************************
// MEDIA QUERY: smartphone portrait
// ***********************************************************************
@media screen and (max-width: 479px) {
    .main-navigation {
        width: 100%;
    }
}