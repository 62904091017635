@keyframes append-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.main-navigation {
  background-color: #d8ecfe;
  height: 60px;
  position: sticky;
  top: 0px;
  width: 100%;
}
.main-navigation ul {
  background-color: #d8ecfe;
  padding: 0 11px;
  box-sizing: border-box;
}
.main-navigation li:hover,
.main-navigation li.on {
  background-color: #b8d6f0;
}
.main-navigation a {
  font-weight: 400;
  transition: none;
}
.main-navigation a:hover {
  color: #0080bc;
}
.main-navigation.mobile-view {
  display: none;
}
.main-navigation.desktop-view {
  z-index: 100;
}
.main-navigation.desktop-view #home-image {
  margin: 0 0 0 6.5px;
  min-width: 70px;
}
.main-navigation.desktop-view #home-image a {
  padding: 0;
}
.main-navigation.desktop-view #home-image a svg {
  margin: 0 auto;
}
.main-navigation.desktop-view .navigation--arrow-down {
  display: inline-block;
  height: auto;
  width: 21px;
  position: relative;
  top: 4px;
  padding-left: 7.5px;
}
.main-navigation.desktop-view .navigation--arrow-down path {
  fill: #0080bc;
}
.main-navigation.desktop-view .home-image {
  height: 36px;
  position: relative;
  top: 12px;
  right: -10px;
}
.main-navigation.desktop-view .home-image path {
  fill: #0080bc;
}
.main-navigation ul[data-level="1"] {
  display: flex;
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0 auto;
  max-width: 1280px;
  min-width: 993px;
  width: 100%;
  justify-content: space-between;
}
.main-navigation ul[data-level="1"] > li {
  color: #0080bc;
  display: inline-block;
  font-size: 16px;
  height: 60px;
  position: relative;
  text-transform: uppercase;
  transition: background-color 0.4s;
}
.main-navigation ul[data-level="1"] > li:after {
  content: '';
  display: block;
  height: 11px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
}
.main-navigation ul[data-level="1"] > li:hover > ul {
  display: block;
}
.main-navigation ul[data-level="1"] > li:last-child > ul {
  right: 0;
}
.main-navigation ul[data-level="1"] > li > a {
  display: inline-block;
  line-height: 60px;
  padding: 0 15px;
  white-space: nowrap;
}
.main-navigation ul[data-level="1"] ul[data-level="2"] {
  display: none;
  position: absolute;
  text-align: left;
  top: 71px;
  z-index: 10;
}
.main-navigation ul[data-level="1"] ul[data-level="2"] > li {
  display: block;
  min-width: 200px;
  padding: 0 15px;
  text-transform: none;
}
.main-navigation ul[data-level="1"] ul[data-level="2"] > li a {
  display: block;
  padding: 15px 0;
  transition: color 0.4s;
}
.main-navigation ul[data-level="1"] ul[data-level="2"] > li.on a {
  font-weight: 500;
}
.navigation--back-to-top-button {
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0.5em;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  margin: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 20px;
  right: 0;
  left: auto;
  bottom: 50px;
  color: #0080bc;
  background-color: #d7ecfe;
}
.navigation--back-to-top-button svg {
  height: auto;
  width: 24px;
}
.navigation--back-to-top-button svg path {
  fill: #0080bc;
}
.navigation--back-to-top-button .navigation--back-to-top-button--text {
  display: none;
}
.navigation--back-to-top-button:hover {
  opacity: 0.7;
}
.navigation--back-to-top-button:hover .navigation--back-to-top-button--text {
  display: inline-block;
}
@media screen and (max-width: 1130px) {
  .main-navigation {
    bottom: 0;
    box-sizing: border-box;
    height: auto;
    overflow: auto;
    padding: 60px 60px 0 20px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: all 200ms;
    width: 350px;
    z-index: 999;
  }
  .main-navigation.mobile-view {
    display: block;
  }
  .main-navigation.desktop-view {
    display: none;
  }
  .main-navigation ul[data-level="1"] {
    display: block;
    height: auto;
    max-width: none;
    min-width: 0;
  }
  .main-navigation ul[data-level="1"] > li {
    display: block;
    text-align: left;
    width: 100%;
  }
  .main-navigation ul[data-level="1"] > li > a {
    font-size: 18px;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .main-navigation ul[data-level="1"] + .js-userpanel-list {
    margin-top: 20px;
  }
  .main-navigation ul[data-level="2"] {
    display: none !important;
  }
  .main-navigation .js-userpanel-list {
    display: block;
    list-style: none;
  }
  .main-navigation .js-userpanel-list > li > a {
    font-size: 14px;
    line-height: 32px;
    display: block;
    padding: 0 15px;
  }
  .main-navigation .js-userpanel-list > li > a.bookmark:before {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  .main-navigation {
    width: 100%;
  }
}
